<template>
  <div class="template-container pc-wrap">
    <div class="wrap-box">
      <!-- 模拟 -->
      <div class="imitate-box">
        <ios-framework-box>
          <component :is="useComponent"></component>
        </ios-framework-box>
      </div>
    </div>
  </div>
</template>

<script setup>
import iosFrameworkBox from "@/components/ios-framework-box/index.vue";

import {
  computed,
  defineAsyncComponent,
  getCurrentInstance,
  reactive,
} from "vue";

const { proxy } = getCurrentInstance();

const routeParams = computed(() => proxy.$route.query);

// 项目名称（组件）
const projectName = computed(() => routeParams.value.projectName);

// 组件集合
const componentBoxs = reactive({
  attractInvestment: import("../../components/attract-investment/index.vue"),
  customerAcquisitionFinance: import(
    "../../components/customer-acquisition-finance/index.vue"
  ),
  businessOportunity: import("../../components/business-oportunity/index.vue"),
  intelligentDevices: import("../../components/intelligent-devices/index.vue"),
  smartArGlasses: import("../../components/smart-ar-glasses/index.vue"),
  cloudPetIntelligence: import(
    "../../components/cloud-pet-intelligence/index.vue"
  ),
  blueNet: import("../../components/blue-net/index.vue"),
  householdEnergyStorage: import(
    "../../components/household-energy-storage/index.vue"
  ),
  B2B2CShoppingMall: import("../../components/b2b2b-shopping-mall/index.vue"),
});
// 使用的组件
const useComponent = computed(() =>
  defineAsyncComponent(() => componentBoxs[projectName.value])
);
</script>

<style lang="scss" scoped>
.template-container {
  height: 100%;

  &.pc-wrap {
    cursor: pointer;
    .wrap-box {
      height: 80vh;
      background-color: rgb(245, 245, 245);
      padding: 30px 0;

      .imitate-box {
        height: 100%;
      }
    }
  }
}
</style>
