<template>
  <web-app v-if="isNavigatorClient" @click="onHandleClick"></web-app>
  <web-pc v-else @click="onHandleClick"></web-pc>
</template>

<script setup>
import webPc from "./client/webPc.vue";
import webApp from "./client/webApp.vue";

import { computed, ref, getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance();

const isNavigatorClient = computed(() => proxy.$isNavigatorClient());

const onHandleClick = ()=>{

  // 跳转demo
  proxy.$router.push('/trialDemo')
}

</script>
