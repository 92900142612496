<template>
  <div class="template-container template-page mobile-wrap">
    <div class="wrap-box">
      <!-- 模拟 -->
      <div class="imitate-box">
        <component :is="useComponent"></component>
      </div>
    </div>
  </div>
</template>

<script setup>
import iosFrameworkBox from "@/components/ios-framework-box/index.vue";

import {
  computed,
  defineAsyncComponent,
  getCurrentInstance,
  reactive,
  onBeforeMount,
  onUnmounted,
} from "vue";
import { useRouter } from "vue-router";

const { proxy } = getCurrentInstance();
const mainStore = proxy.$usePiniaModule("mainStore");

const routeParams = computed(() => proxy.$route.query);

// 项目名称（组件）
const projectName = computed(() => routeParams.value.projectName);

// 组件集合
const componentBoxs = reactive({
  attractInvestment: import("../../components/attract-investment/index.vue"),
  customerAcquisitionFinance: import(
    "../../components/customer-acquisition-finance/index.vue"
  ),
  businessOportunity: import("../../components/business-oportunity/index.vue"),
  intelligentDevices: import("../../components/intelligent-devices/index.vue"),
  smartArGlasses: import("../../components/smart-ar-glasses/index.vue"),
  cloudPetIntelligence: import(
    "../../components/cloud-pet-intelligence/index.vue"
  ),
  blueNet: import("../../components/blue-net/index.vue"),
  householdEnergyStorage: import(
    "../../components/household-energy-storage/index.vue"
  ),
  B2B2CShoppingMall: import("../../components/b2b2b-shopping-mall/index.vue"),
});

// 使用的组件
const useComponent = computed(() =>
  defineAsyncComponent(() => componentBoxs[projectName.value])
);

// 控制框架组件
const controlLayout = (result) => {
  mainStore.showHeaderNavbar = result;
  mainStore.showDownFooter = result;
};

onBeforeMount(() => {
  controlLayout(false);
});
</script>

<style lang="scss">
.template-page {
  height: 100%;

  &.mobile-wrap {
    .wrap-box {
      height: 100vh;
      overflow: hidden;

      .wrap-content {
        // padding: 0 20px 60px;
        border-radius: 0;
        background-color: white;
      }
      .imitate-box {
        height: 100%;
      }
    }
  }

  // 商机小程序
  .business-oportunity {
    .wrap-content__body {
      margin-top: 60px !important;
    }
    // 顶部搜索
    .wrap-content__header {
      .mini-program-navbar {
        padding-top: 25px !important;
        .capsule-fixed {
          top: 15px !important;
        }

        .wrap-content {
          background-color: transparent !important;
        }
      }
    }

    // 筛选
    .tab-box {
      .sticky-box {
        top: 55px !important;
      }
    }
  }

  // 智能设备
  .intelligent-devices {
    .navigation {
      margin-top: 10px !important;
    }
  }

  // 智能AR眼镜
  .smart-ar-glasses {
    .top-title {
      margin-top: 20px !important;
    }
  }

  // 云宠智能
  .cloud-pet-intelligence {
    .top-title {
      margin-top: 30px !important;
    }
  }

  // 蓝网科技远程会议
  .blue-net {
    .top_box {
      height: 183px !important;
      .top-title {
        margin-top: 20px !important;
      }
    }

    .show_chat_box {
      padding-bottom: 13px !important;
      .send {
        margin-top: 10px !important;
      }
    }
  }

  // 家庭储能
  .household-energy-storage {
    .top_box {
      .top-title {
        margin-top: 20px !important;
      }
    }

    .content-box {
      margin-top: 10px !important;
    }
  }

  // B2B2C 购物商城
  .b2b2b-shopping-mall {
    .nav_box {
      .top-title {
        padding-top: 10px !important;
      }
    }

    .content-box {
      height: calc(100% - 91px - 60px) !important;
    }
  }

  .mini-program-navbar {
    .wrap-content {
      background-color: transparent !important;
    }
  }
}
</style>
