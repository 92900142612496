<template>
  <div class="imitatepage-layout">
    <template-index></template-index>
  </div>
</template>

<script setup>
import templateIndex from "./template/index.vue";

import { getCurrentInstance, onUnmounted } from "vue";

const { proxy } = getCurrentInstance();

const mainStore = proxy.$usePiniaModule("mainStore");

// 控制框架组件
const controlLayout = (result) => {
  mainStore.showHeaderNavbar = result;
  mainStore.showDownFooter = result;
};

onUnmounted(() => {
  controlLayout(true);
});
</script>

<style lang="scss" scoped>
.imitatepage-layout {
  // display: grid;
  // grid-template-rows: auto 1fr auto;
  // min-height: 100vh;
  height: 100%;
  background-color: rgba(245, 245, 245, 1);
}
</style>
